<template>
	<div>
		<div class="header">
			<div class="header-left">
				<van-icon name="arrow-left" class="icon" @click="$router.back()" />
			</div>
			<div class="header-title">家长信息填报</div>
			<div class="header-right">
				<van-icon name="arrow-left" class="icon" style="color: #eeeeee" />
			</div>
		</div>
	</div>
	<div class="body">
		<p class="title_str">家长姓名</p>
		<input class="title_int" placeholder="请输入家长姓名" v-model="name" @blur="nameBlur" />
		<!-- <p style="font-size: 12px; color: red" v-if="sizeShow">不可输入特殊符号</p> -->
		<p class="title_str">与学生关系</p>
		<input class="title_int" placeholder="请选择与学生关系" v-model="relation_str" @focus="relatiFocus" />
		<p class="title_str" v-if="show_input">实际关系</p>
		<input class="title_int" placeholder="请输入实际关系" v-model="relation_name" v-if="show_input" />
		<p class="title_str">联系电话</p>
		<input class="title_int" placeholder="请输入联系电话" v-model="phone" @blur="phoneBlur" />
		<p class="title_str" style="margin-top: 20px">您对孩子的期望</p>
		<div style="width: 100%">
			<textarea
				placeholder="请输入您对孩子的期望"
				style="height: 150px; width: 100%; background: #ffffff; border: 1px solid #dddddd; border-radius: 4px; resize: none"
				type="textarea"
				v-model="wish"
			/>
		</div>
<!--		<span style="font-size: 14px; color: #ccc" :style="{ color: wish.length > 300 ? 'red' : '#ccc' }">{{ wish.length }} / 300</span>-->

    <p class="title_str" style="margin-top: 20px">在您眼中，孩子的性格是什么样的？</p>
    <div style="width: 100%">
			<textarea
          placeholder="请输入您孩子的性格，老师们会根据您孩子的性格因材施教"
          style="height: 150px; width: 100%; background: #ffffff; border: 1px solid #dddddd; border-radius: 4px; resize: none"
          type="textarea"
          v-model="character_description"
      />
    </div>
<!--    <span style="font-size: 14px; color: #ccc" :style="{ color: character_description.length > 300 ? 'red' : '#ccc' }">{{ character_description.length }} / 300</span>-->
	</div>
	<van-action-sheet v-model:show="show" :actions="actions" @select="onSelect" cancel-text="取消" />
	<van-button class="save" block @click="submit" :loading="loading">保存</van-button>
	<div style="height: 24px; width: 100%"></div>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'
import { Dialog } from 'vant'
import { useRouter } from 'vue-router'
export default {
	setup() {
		const router = useRouter()
		const name = ref('') // 家长姓名
		const relation = ref('') // 关系
		const relation_str = ref('') // 关系
		const relation_name = ref('') // 实际关系
		const phone = ref('') // 联系电话
    const wish = ref('') // 期望
    const character_description = ref('') // 期望
		const show_input = ref(false) // 是否显示实际关系输入框
		const show = ref(false)
		let sizeShow = ref(false)
		const loading = ref(false)
		const actions = [
			{ name: '父亲', value: 1 },
			{ name: '母亲', value: 2 },
			{ name: '其他', value: 3 }
		]
		const onSelect = item => {
			relation_str.value = item.name
			relation.value = item.value
			show.value = false
			show_input.value = item.value === 3;
		}
		return {
			router,
			name,
			relation,
			wish,
			show,
			phone,
			relation_name,
			relation_str,
			show_input,
			actions,
			onSelect,
			sizeShow,
			loading,
      character_description
		}
	},
	methods: {
		submit() {
			// if (this.relation == '') {
			// 	if (this.name == '' && this.relation == '' && this.phone == '') {
			// 		Toast.fail('请完善你的信息')
			// 		return
			// 	}
			// }
			// if (this.relation == 3) {
			// 	if (this.name == '' && this.phone == '' && this.relation_name == '') {
			// 		Toast.fail('请完善你的信息')
			// 		return
			// 	}
			// 	if (this.relation_name == '') {
			// 		Toast.fail('请输入实际关系')
			// 		return
			// 	}
			// }
			// if (this.name == '') {
			// 	Toast.fail('请填写家长姓名')
			// 	return
			// }
			// if (this.phone == '') {
			// 	Toast.fail('请填写联系电话')
			// 	return
			// }
			// if (this.wish.length > 100) {
			// 	Dialog.alert({
			// 		message: '对孩子的期望最多输入100个字',
			// 		confirmButtonText: '我知道了'
			// 	})
			// 	return
			// }
			this.loading = true
			// if (this.relation == 3) {
			axios({
				url: 'fill/add',
				method: 'post',
				data: {
					identifying: sessionStorage.getItem('user'),
					name: this.name,
					relation: this.relation,
					relation_name: this.relation_name,
					phone: this.phone,
					wish: this.wish,
          character_description: this.character_description

				}
			}).then(res => {
				this.loading = false
				if (res.data.error.errorCode == 0) {
					Dialog.alert({
						message: '新增成功',
						confirmButtonText: '我知道了'
					}).then(() => {
						// this.$router.push('/about')
            this.$router.replace('/about')
					})
				} else {
					Dialog.alert({
						message: res.data.error.errorUserMsg,
						confirmButtonText: '我知道了'
					})
				}
			})
			// } else {
			// 	axios({
			// 		url: 'fill/add',
			// 		method: 'post',
			// 		data: {
			// 			identifying: sessionStorage.getItem('user'),
			// 			name: this.name,
			// 			relation: this.relation,
			// 			phone: this.phone,
			// 			wish: this.wish
			// 		}
			// 	}).then(res => {
			// 		this.loading = false
			// 		if (res.data.error.errorCode == 0) {
			// 			Dialog.alert({
			// 				message: '新增成功',
			// 				confirmButtonText: '我知道了'
			// 			}).then(() => {
			// 				this.$router.back()
			// 			})
			// 		} else {
			// 			Dialog.alert({
			// 				message: res.data.error.errorUserMsg,
			// 				confirmButtonText: '我知道了'
			// 			})
			// 		}
			// 	})
			// }
		},
		relatiFocus() {
			this.show = true
			document.activeElement.blur()
		},
		phoneBlur() {
			// if (this.phone.length != 11) {
			// 	Toast.fail('请输入正确的手机号')
			// } else {
			// 	var pattern = /^1[3|4|5|7|8|9][0-9]{9}$/
			// 	if (!pattern.test(this.phone)) {
			// 		Toast.fail('手机号格式错误')
			// 	}
			// }
		},
		nameBlur() {
			if (this.name.length != 0 && this.name.length < 20) {
				let pattern = /[\u4e00-\u9fa5，。！、]+/
				this.sizeShow = !pattern.test(this.name)
				// if (!pattern.test(this.name)) {
				// 	Toast.fail('姓名格式错误')
				// }
			}
			// if (this.name.length > 20) {
			// 	Toast.fail('姓名不能超过20个字')
			// }
		}
	}
}
</script>

<style lang="less" scoped>
.header {
	background-color: #eeeeee;
	height: 50px;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	color: #333;
	border: 1px solid #eee;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 16px;
	padding: 0 10px;
	.icon {
		line-height: 50px;
		font-size: 24px;
	}
}
.body {
	width: 80%;
	padding-top: 20px;
	padding-left: 10%;
	.title_str {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
	}
	.title_int {
		width: 100%;
		height: 40px;
		border: 0px solid #ccc;
		border-bottom: 1px solid #dddddd;
		opacity: 1;
		margin-bottom: 10px;
	}
}

.save {
	background-color: #1d2088;
	color: #fff;
	width: 80%;
	margin-left: 10%;
	border-radius: 5px;
	margin-top: 20px;
}
</style>